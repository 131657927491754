import { generateClientFromSwaggerPaths } from './generateClientFromSwagger'
import type { paths } from './fd-apps-api-types'

import type { ParallelRoutes } from '../../../shopify.fittdesign.workers.dev/src/api-controller'

// const baseUrl = `http://localhost:3000`
const baseUrl = 'https://fd-shopify-app.fly.dev'
const fdAppsAPI = generateClientFromSwaggerPaths<paths>({
	baseUrl,
	axios: null
})

const cfworkerAPI = generateClientFromSwaggerPaths<ParallelRoutes>({
	baseUrl: 'https://shopify.fittdesign.workers.dev',
	axios: null
})

export { fdAppsAPI, cfworkerAPI }