import { CSSProperties, useRef, useState } from "react"
import SVGIcon, { SVGIconProps } from "./ui/SVGIcon"

type Props = {
	id: string
	style?: CSSProperties
	title: JSX.Element
	svgIconProps?: SVGIconProps
	titleWrapperStyle?: CSSProperties
	defaultIsOpen?: boolean
	renderBody: () => JSX.Element
}

const CollapsibleItem = (props: Props) => {
	const internalRef = useRef<{
		maxHeight: number | "fit-content"
		parentRef: null | HTMLDivElement
	}>({
		parentRef: null,
		maxHeight: props.defaultIsOpen === true ? "fit-content" : 0
	})

	const [isOpen, setIsOpen] = useState(props.defaultIsOpen === true)

	return (
		<div
			data-test="collapsible-item"
			style={{
				...props.style
			}}>
			<div
				style={{
					cursor: "pointer",
					padding: "10px 0px",
					position: "relative",
					fontWeight: "bold",
					width: "100%",
					...props.titleWrapperStyle
				}}
				className="flex items-center justify-between"
				onClick={() => {
					setIsOpen(!isOpen)
				}}>
				{props.title}

				<div
					style={{
						position: "absolute",
						zIndex: 1,
						top: "50%",
						right: 10,
						transform: "translateY(-50%)"
					}}>
					<span
						style={{
							display: "block",
							transition: "transform .2s ease-in-out",
							transform: `rotate(${isOpen === true ? "180" : "0"}deg)`
						}}>
						<SVGIcon
							size={20}
							color="black"
							name={"chevron-down"}
							{...props.svgIconProps}
						/>
					</span>
				</div>
			</div>
			<div
				ref={(ref) => {
					internalRef.current.parentRef = ref
					if (ref == null) {
						return
					}

					// slight delay needed
					setTimeout(() => {
						const maxHeight = ref.scrollHeight
						// need to set this each time in case the # of filters change
						internalRef.current.maxHeight = maxHeight
						if (isOpen !== true) {
							return
						}

						if (internalRef.current.parentRef == null) {
							// probably just means it was umounted
							// console.error(`CollapsibleItem(${props.id}) internalRef.current.parentRef is not defined`)
							return
						}

						if (maxHeight === 0) {
							return
						}

						ref.style.maxHeight = maxHeight + "px"

						// setTimeout(() => {
						//    // ref.scrollIntoView({
						//    //    block: 'start'
						//    // })
						//    ref.scrollBy({
						//       top: 100
						//    })
						// }, 250)
					}, 3)
				}}
				style={{
					overflow: "hidden",
					transition: ".2s max-height linear",
					maxHeight: isOpen === true ? internalRef.current.maxHeight : 0
				}}>
				{props.renderBody()}
			</div>
		</div>
	)
}

export default CollapsibleItem
