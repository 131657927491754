// /* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// const IN_FLIGHT_CACHE_KEYS: {
// 	[key: string]: boolean
// } = {}

// import 'isomorphic-fetch'
// DO NO REENABLE THIS AS IT CAUSES ISSUES WITH NEXT
// if (typeof window === 'undefined') {
// 	var FormData = require('form-data')
// 	global['FormData'] = FormData
// }

import type { AxiosInstance } from "axios"

export class APIReqError extends Error {
	readonly error: {
		message: string
	}

	// base constructor only accepts string message as an argument
	// we extend it here to accept an object, allowing us to pass other data
	constructor(error: APIReqError['error']) {
		super(error.message)
		this.error = error
	}
}

const parseCookies = (cookiesKV: { [key: string]: string }) => {
	const cookies: Array<string> = []
	for (const key in cookiesKV) {
		const value = cookiesKV[key]
		if (value != null) {
			cookies.push(`${key}=${value}`)
		}
	}

	return cookies.join(' ;')
}

type ObtainKeys<Obj, Type> = {
	[Prop in keyof Obj]: Obj[Prop] extends Type ? Prop : never
}[keyof Obj]

type GetPaths<Paths extends IPaths> = ObtainKeys<Paths, { get: any }>
type PostPaths<Paths extends IPaths> = ObtainKeys<Paths, { post: any }>
type PatchPaths<Paths extends IPaths> = ObtainKeys<Paths, { patch: any }>
type DeletePaths<Paths extends IPaths> = ObtainKeys<Paths, { delete: any }>
type PutPaths<Paths extends IPaths> = ObtainKeys<Paths, { put: any }>

type IPaths = {
	[key: string]: any
}

type DataArg<
	M extends 'put' | 'post',
	paths extends IPaths,
	P extends PutPaths<paths> | PostPaths<paths>,
> =
	paths[P][M] extends {
		requestBody: any,
		parameters: {
			query: any,
			path: any
		}
	} ?
	{
		body: paths[P][M] extends { requestBody: any } ? paths[P][M]['requestBody']['content']['application/json'] : null,
		query: paths[P][M]['parameters'] extends { query: any } ? paths[P][M]['parameters']['query'] : null,
		path: paths[P][M]['parameters'] extends { path: any } ? paths[P][M]['parameters']['path'] : null
	} :
	{
		body: paths[P][M] extends { requestBody: any } ? paths[P][M]['requestBody']['content']['application/json'] : null,
		query: paths[P][M]['parameters'] extends { query: any } ? paths[P][M]['parameters']['query'] : null,
	}

type API<paths extends IPaths> = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	get<P extends GetPaths<paths>>(path: P, d: paths[P]['get']['parameters'] & {
		query: {},
		cookies?: Record<string, undefined | string>,
		headers?: {
			cookie?: string
		}
	}): Promise<
		paths[P]['get']['responses'][200] extends { content: any } ? paths[P]['get']['responses'][200]['content']['application/json'] : void
	>,

	put<P extends PutPaths<paths>>(
		path: P,
		d: DataArg<'put', paths, P>
	): Promise<
		paths[P]['put']['responses'][200] extends { content: any } ? paths[P]['put']['responses'][200]['content']['application/json'] : void
	>,

	post<P extends PostPaths<paths>>(
		path: P,
		d: DataArg<'post', paths, P>
	): Promise<
		paths[P]['post']['responses'][200] extends { content: any } ? paths[P]['post']['responses'][200]['content']['application/json'] : void
	>,

	// post<P extends PostPaths<paths>>(
	// 	path: P,
	// 	d: paths[P]['post']['parameters'] extends { query?: any, path?: any } ? {
	// 		path: paths[P]['post']['parameters']['path'],
	// 		query: paths[P]['post']['parameters']['query'],
	// 		body: paths[P]['post'] extends { requestBody: any } ? paths[P]['post']['requestBody']['content']['application/json'] : null,
	// 	} : {
	// 		body: paths[P]['post'] extends { requestBody: any } ? paths[P]['post']['requestBody']['content']['application/json'] : null,
	// 	}): Promise<
	// 		paths[P]['post']['responses'][200] extends { content: any } ? paths[P]['post']['responses'][200]['content']['application/json'] : void
	// 	>,

	patch<P extends PatchPaths<paths>>(path: P, d: {
		body: paths[P]['patch'] extends { requestBody: any } ? paths[P]['patch']['requestBody']['content']['application/json'] : null,
		query: paths[P]['patch']['parameters'] extends { query: any } ? paths[P]['patch']['parameters']['query'] : {},
		path: paths[P]['patch']['parameters'] extends { path: any } ? paths[P]['patch']['parameters']['path'] : {},
	}): Promise<
		paths[P]['patch']['responses'][200] extends { content: any } ? paths[P]['patch']['responses'][200]['content']['application/json'] : void
	>,

	delete<P extends DeletePaths<paths>>(path: P, d: paths[P]['delete']['parameters']): Promise<
		paths[P]['delete']['responses'][200] extends { content: any } ? paths[P]['delete']['responses'][200]['content']['application/json'] : void
	>,

	ReqError: typeof APIReqError
}

function generateClientFromSwaggerPaths<P extends {}>(opts: {
	baseUrl: string,
	axios: null | AxiosInstance
}): API<P> {
	const { baseUrl } = opts

	const handleReq = async (method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE', path: string, data: {
		body?: null | Record<string, any>,
		query?: Record<string, any>,
		cookies?: {
			[key: string]: string
		}
		path?: {
			[key: string]: string
		}
	}) => {
		if (data.path != null) {
			const dataPath = data.path
			Object.keys(dataPath).forEach((key) => {
				const value = dataPath[key]!
				if (path.includes(`/:${key}`) === true) {
					path = path.replace(`/:${key}`, `/${value}`)
				}
				else {
					path = path.replace(`{${key}}`, value)
				}
			})
		}

		const url = new URL(baseUrl + path)

		// TODO: remove null values to avoid them from being sent as 'null'
		if (data?.query != null) {
			url.search = new URLSearchParams(data.query).toString()
		}

		const headers: RequestInit['headers'] = {
			// ...data?.headers
		}

		if (method !== 'GET') {
			headers['Content-Type'] = 'application/json'
		}

		if (data.cookies) {
			headers['cookie'] = parseCookies(data.cookies)
		}

		let body: null | undefined | string | Record<string, any> = data.body
		if (body instanceof FormData) {
			// Remove content-type to allow file uploads
			delete headers['Content-Type']
		}
		else if (body != null) {
			body = JSON.stringify(data.body)
		}
		else if (body === null) {
			// make sure not to set this to null if it is null
			body = undefined
		}

		if (opts.axios != null) {
			const method_lc: Lowercase<typeof method> = method.toLowerCase() as any
			return opts.axios[method_lc](url.toString(), body, {
				headers: headers,
			}).then((res) => res.data)
		}

		const res = await fetch(url.toString(), {
			method: method,
			headers,
			// make sure not to set this to null if it is null
			body
		})

		if (res.redirected === true) {
			window.location.assign(res.url)
			return
		}

		if (res.ok !== true) {
			const body = await res.json()
			throw new APIReqError(body.error)
		}

		const resData = await res.json().catch(() => { /* */ })
		return resData
	}

	return {
		ReqError: APIReqError,
		async get(path: string, data: any) {
			return handleReq('GET', path, data)
		},

		async post(path: string, data: any) {
			return handleReq('POST', path, data)
		},

		async put(path: string, data: any) {
			return handleReq('PUT', path, data)
		},

		async patch(path: string, data: any) {
			return handleReq('PATCH', path, data)
		},

		async delete(path: string, data: any) {
			return handleReq('DELETE', path, data)
		},
	} as any as API<P>
}

export { generateClientFromSwaggerPaths }