import TypedLocalStore from "typed-local-store"
import { useGlobalStore } from "../../shared/useGlobalStore"

import { cfworkerAPI, fdAppsAPI } from "../../shared/utils/fd-apps-api-client"
import { App } from "../../types"

type InventoryItem = App.ShopifyProductInventoryData["inventoryItems"][0]

// TODO: expire values
const LS_KEYS = {
	selectedLocation: "selectedLocation_fdv3",
	variantInventoryItem: "variantInventoryItem_fdv3"
} as const

export default class LocationService {
	static getLocalStorage() {
		const typedStorage = new TypedLocalStore<{
			[LS_KEYS.selectedLocation]: App.ShopifyLocation
			[LS_KEYS.variantInventoryItem]: {
				[key: string]: InventoryItem & {
					_storedAt: Date
				}
			}
		}>()
		return typedStorage
	}

	static init() {
		void cfworkerAPI
			.get("/apps/brando/api/v1/locations", {
				query: {
					shop: useGlobalStore.getState().myshopifyDomain
				}
			})
			.then((res) => {
				useGlobalStore.setState({
					locations: res.locations,
					selectedLocation: this.getSelectedLocation()
				})
			})
	}

	static async getLocationCartStatus(args: {
		location: App.ShopifyLocation
		cart: null | App.InitializedCart
	}) {
		const { cart, location } = args
		let available = 0
		let unavailable = 0

		// Guard
		if (cart == null) {
			return null
		}

		for (const item of cart.lines) {
			const inventoryItem = await this.getInventoryItemForCartItem({ item })
			if (inventoryItem == null) {
				continue
			}

			const status = this.getVariantInventoryStatus({
				inventoryItem,
				location
			})

			if (status.pickUp === true) {
				available++
			} else {
				unavailable++
			}
		}

		let status: "all-available" | "partially-available" | "unavailable" =
			"all-available"
		if (available === 0) {
			status = "unavailable"
		} else if (available > 0 && unavailable > 0) {
			status = "partially-available"
		}

		return {
			status,
			available,
			unavailable
		}
	}

	static getVariantInventoryStatus(args: {
		inventoryItem: InventoryItem
		// by passing in null we can get shippable status by itself
		location: null | App.ShopifyLocation
	}): {
		// shippable is independent on passed in location
		shippable: boolean
		pickUp: boolean
	} {
		const { inventoryItem, location } = args

		const locationInventoryLevel = inventoryItem.inventoryLevels.find(
			(iLevel: { location: { id: any } }) => {
				if (location == null) {
					return false
				}
				return iLevel.location.id === location.id
			}
		)

		const shippable =
			inventoryItem.inventoryLevels.find(
				(level: { available: number; location: { shipsInventory: boolean } }) => {
					return level.available > 0 && level.location.shipsInventory === true
				}
			) != null

		if (locationInventoryLevel == null) {
			return {
				shippable,
				pickUp: false
			}
		}

		const pickUp =
			locationInventoryLevel.available > 0 &&
			locationInventoryLevel.location.fulfillsOnlineOrders === true

		return {
			shippable,
			pickUp
		}
	}

	static async getCartInventoryStatus(args: { cart: App.InitializedCart }) {
		let cartItemThatCannotBeShipped: null | App.InitializedCart["lines"][0] = null
		let canBePickedUpAtSelectedLocation = true
		const selectedLocation = this.getSelectedLocation()

		if (selectedLocation == null) {
			canBePickedUpAtSelectedLocation = false
		}

		for (const item of args.cart.lines) {
			const inventoryItem = await this.getInventoryItemForCartItem({ item })
			if (inventoryItem == null) {
				continue
			}

			const status = this.getVariantInventoryStatus({
				inventoryItem,
				location: selectedLocation
			})

			if (status.shippable === false) {
				cartItemThatCannotBeShipped = item
			}

			if (status.pickUp !== true) {
				canBePickedUpAtSelectedLocation = false
			}
		}

		return {
			shippable: cartItemThatCannotBeShipped == null,
			canBePickedUpAtSelectedLocation
		}
	}

	static async getProductInventoryItems(args: { productId: string }) {
		return cfworkerAPI.get("/apps/brando/api/v1/product-inventory", {
			query: {
				// this expects the non-gid id for the product
				productShopifyId: args.productId.toString().split("/").reverse()[0]!,
				shop: useGlobalStore.getState().myshopifyDomain
			}
		})
	}

	static storeVariantInventoryItem(args: { inventoryItem: InventoryItem }) {
		const inventoryItems =
			this.getLocalStorage().getItem(LS_KEYS.variantInventoryItem) || {}
		inventoryItems[args.inventoryItem.productVariantShopifyId] = {
			...args.inventoryItem,
			_storedAt: new Date()
		}

		this.getLocalStorage().setItem(LS_KEYS.variantInventoryItem, inventoryItems)
	}

	static staticInventoryItemsInfetch: Array<string> = []

	static async getInventoryItemForVariant(args: {
		minAvailableQty?: number
		variant: {
			id: string
			productId: string
		}
	}): Promise<null | InventoryItem> {
		const inventoryItems =
			this.getLocalStorage().getItem(LS_KEYS.variantInventoryItem) || {}

		const inventoryItem = inventoryItems[args.variant.id]
		if (inventoryItem != null) {
			const _storedAt = new Date(inventoryItem._storedAt)
			const diffInMins = (Date.now() - _storedAt.getTime()) / 1000 / 60
			if (diffInMins < 10) {
				return inventoryItem
			}
		}

		// TODO: probably should make this better or address the reason we are doing this (which is the rendering of LocationCard)
		const isFetching = this.staticInventoryItemsInfetch.includes(args.variant.id)
		if (isFetching === true) {
			await new Promise((res) => setTimeout(() => res(null), 100))
			return this.getInventoryItemForVariant(args)
		}

		this.staticInventoryItemsInfetch.push(args.variant.id)

		const productInventoryItems = await this.getProductInventoryItems({
			productId: args.variant.productId
		})

		const _inventoryItem =
			productInventoryItems.inventoryItems.find(
				(inventoryItem: { productVariantShopifyId: string }) => {
					return inventoryItem.productVariantShopifyId.endsWith(`${args.variant.id}`)
				}
			) || null

		if (_inventoryItem == null) {
			console.error(
				`FD: could not find inventory item for variant id (${args.variant.id}) even though we fetched it from the server`
			)
		} else {
			this.storeVariantInventoryItem({ inventoryItem: _inventoryItem })
		}

		this.staticInventoryItemsInfetch = this.staticInventoryItemsInfetch.filter(
			(value) => value !== args.variant.id
		)

		return _inventoryItem
	}

	static async getInventoryItemForCartItem(args: {
		item: App.InitializedCart["lines"][0]
	}): Promise<null | InventoryItem> {
		return this.getInventoryItemForVariant({
			minAvailableQty: args.item.quantity,
			variant: {
				id: args.item.merchandise.id,
				productId: args.item.merchandise.product.id
			}
		})
	}

	// static storeVariantInventory(data: {
	// 	variantId: number,
	// 	inventory: App.ShopifyProductInventoryData['inventoryItems'][0]
	// }) {
	// 	const cartItemsInventoryData = typedStorage.getItem('cartItemsInventoryData_fdv1')
	// }

	static getSelectedLocation(): null | App.ShopifyLocation {
		return this.getLocalStorage().getItem(LS_KEYS.selectedLocation)
	}

	static setSelectedLocation(location: App.ShopifyLocation) {
		useGlobalStore.setState({ selectedLocation: location })
		this.getLocalStorage().setItem(LS_KEYS.selectedLocation, location)
	}
}
